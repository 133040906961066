<template>
  <group-filter></group-filter>
</template>

<script>
import store from "../../store/store";
import GroupFilter from "./GroupFilter";
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "GroupsReport",
  mixins: [Mixin],
  components: {
    GroupFilter,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    store.commit("showLoader", false); // Loader Off
  },
};
</script>

