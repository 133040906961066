<template>
  <CCard>
    <CCardHeader>
      <strong>Group Report</strong>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12" align="left">
          <div class="form-group">
            <label class="form__label" style="vertical-align: text-bottom">{{
                $lang.report.filter.date
              }}</label> &nbsp;
            <template>
              <date-range-picker v-model="dateRange"

                                 :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                 :showWeekNumbers="showWeekNumbers"
                                 :showDropdowns="showDropdowns"
                                 :autoApply="autoApply"
                                 :timePicker="timePicker"
                                 :timePicker24Hour="timePicker24Hour"
              >
                <!--    header slot-->
                <!--    input slot (new slot syntax)-->
                <template #input="picker" style="min-width: 350px;">
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <!--    date slot-->
                <template #date="data">
                  <span class="small">{{ data.date | dateCell }}</span>
                </template>
                <!--    ranges (new slot syntax) -->
                <template #ranges="ranges">
                  <div class="ranges">
                    <ul>
                      <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                        <b>{{ name }}</b> <small class="text-muted">{{ range[0].toDateString() }} -
                        {{ range[1].toDateString() }}</small>
                      </li>
                    </ul>
                  </div>
                </template>
                <!--    footer slot-->
                <div slot="footer" slot-scope="data" class="slot">
                  <div>
                    <b class="text-black">Current Week (m/d/Y)</b> : {{ data.rangeText }}
                  </div>
                  <div style="margin-left: auto">
                    <a @click="data.clickApply" v-if="!data.in_selection" class="btn btn-primary btn-sm">Choose
                      Current</a>
                  </div>
                </div>
              </date-range-picker>
            </template>
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
                $lang.report.filter.industry
              }}</label>
            <v-select
                :options="industryOptions"
                v-model="filter.industry"
            ></v-select>
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group">
            <label class="form__label">{{
                $lang.report.filter.countries
              }}</label>
            <v-select
                :options="CountryOptions"
                v-model="filter.country"
                :clearable="true"

             >
            </v-select>
          </div>
        </CCol>

        <CCol sm="3">
          <div class="form-group" style="margin-top: 30px">
            <CButton
                type="submit"
                size="sm"
                color="primary"
                @click="applyFilter()"
                v-c-tooltip="$lang.common.filter.button.filter"
            >
              {{ $lang.common.filter.button.filter }}
            </CButton>
            &nbsp;
            <CButton
                color="danger"
                size="sm"
                @click="resetFilter()"
                v-c-tooltip="$lang.common.filter.button.cancel"
            >
              {{ $lang.common.filter.button.cancel }}
            </CButton>
          </div>
        </CCol>
      </CRow>

      <highcharts class="hc" :options="IndividualNationalChart" ref="chart"></highcharts>
      <highcharts class="hc" :options="IndividualStateChart" ref="chart"></highcharts>
      <highcharts class="hc" :options="CompanyNationalChart" ref="chart"></highcharts>
      <highcharts class="hc" :options="CompanyStateChart" ref="chart"></highcharts>


    </CCardBody>
  </CCard>
</template>
<style scoped>
.slot {
  background-color: #3c5de8;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: white;
}
</style>
<script>
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";
drilldown(Highcharts);
import exporting from "highcharts/modules/exporting";
exporting(Highcharts);
import exportDataHC from "highcharts/modules/export-data";
exportDataHC(Highcharts);
Vue.use(HighchartsVue)

import Vue from "vue";
import {ServerTable} from "vue-tables-2";
import store from "../../store/store";
import {Mixin} from "/src/mixins/index.js";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  country,
  industry,
  state
} from "../../store/url";

Vue.use(ServerTable, {}, false);
let url;
export default {
  name: "GroupFilter",
  mixins: [Mixin],
  components: {
    DateRangePicker
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 6)
    return {
      self: this,
    IndividualNationalChart: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Individual Groups - National'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
    IndividualStateChart: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Individual Groups - Local'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
    CompanyNationalChart: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Company Groups - National'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },
    CompanyStateChart: {
        chart: {
          type: 'column',
        },
        title: {
          align: 'left',
          text: 'Top 10 Company Groups - Local'
        },
        subtitle: {
          text: ''
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          title: {
            text: '',
            align: 'high'
          }
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:f}'
            }
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [],
        drilldown: {
          breadcrumbs: {
            position: {
              align: 'right'
            }
          },
          series: []
        }
      },

    direction: 'ltr',
      format: 'mm/dd/yyyy',
      singleDatePicker: 'range',
      min: [0, 1000000],
      max: [0, 1000000],
      number: 1000,
      appendToBody: true,
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 1,
      showWeekNumbers: true,
      showDropdowns: true,
      autoApply: true,
      timePicker: false,
      timePicker24Hour: false,
      dateRange: {startDate, endDate},
      industryOptions: [],
      CountryOptions: [],
      StateOptions: [],
      stateModule: state,
      moduleIndustry: industry,
      countryModule: country,
      filter: {
        industry: "",
        country: "",
      },
    };
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value)

      return dt.getDate()
    },
    date(val) {
      return val ? val.toLocaleString() : ''
    }
  },
  created() {
    url = '/reports/groups',
    this.loadIndustry();
    this.countryList();
  },
  methods: {

    loadState() {
      let self = this;
      const postData = {countryId: self.filter.country.value};
      axios
          .post(this.listUrlApi(this.stateModule), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },

    loadIndustry() {
      let self = this;
      axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
        response.data.data.map(function (value, key) {
          if (value.industryId === "") {
            self.industryOptions.push({value: value.id, label: value.industry});
          }
        });
      });
    },
    countryList() {
      let self = this;
      axios.get(this.listUrlApi(this.countryModule)).then((response) => {
        response.data.data.map(function (value, key) {
          self.CountryOptions.push({value: value.id, label: value.name});
        });
      });
    },

    registerReport(reqObj) {
      this.individualNational(reqObj);
      this.individualState(reqObj);
      this.companyNational(reqObj);
      this.companyState(reqObj);
    },
    individualNational(reqObj) {
      let self = this;
      axios.post(url + '/individual-national', reqObj)
          .then((response) => {
            self.IndividualNationalChart.series = response.data.data ?? [];
          });
    },
    individualState(reqObj) {
      let self = this;
      axios.post(url + '/individual-state', reqObj)
          .then((response) => {
            self.IndividualStateChart.series = response.data.data ?? [];
          });
    },
    companyNational(reqObj) {
      let self = this;
      axios.post(url + '/companies-national', reqObj)
          .then((response) => {
            self.CompanyNationalChart.series = response.data.data ?? [];
          });
    },
    companyState(reqObj) {
      let self = this;
      axios.post(url + '/companies-state', reqObj)
          .then((response) => {
            self.CompanyStateChart.series = response.data.data ?? [];
          });
    },

    applyFilter() {
      let self = this;
      let industry = "";
      let country, city, services, size, status = "";
      self.selectedServices = [];
      self.selectedSize = [];
      industry = this.checkNullValue(this.filter.industry) ? this.filter.industry.value : '';
      country = this.checkNullValue(this.filter.country) ? this.filter.country.value : '';
      const reqObj = {
        dateRange: this.dateRange,
        industry: industry,
        country: country,
      };
      store.commit("showLoader", true);
      this.filterReport(reqObj);
    },
    resetFilter() {
      let self = this;
      this.$data.filter = [];
      self.services = [];
      this.filterReport();
    },
    filterReport(params) {
      if (!params) {
        params = {dateRange: this.dateRange};
      }
      this.registerReport(params);
      store.commit("showLoader", false);
    }
  },
  mounted() {
    store.commit("showLoader", false); // Loader Off
    this.filterReport()
  },
};
</script>
<style>
div .error {
  color: red;
}


.hasError label {
  color: red;
}


.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

#container {
  height: 400px;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

</style>
